<template>
  <div class="wrap-select-input" v-click-outside="onClickOutside">
    <div v-if="title" class="title">{{ title }}</div>
    <div class="select" :class="{ active: optionShow }">
      <div class="select-selected" @click="optionShow = !optionShow">
        <input
          :lang="userFlatObj.language"
          type="text"
          v-model.trim="inputValue"
          @click.stop="handleClick"
          @keyup.enter="optionShow = false"
          :placeholder="placeholder"
        />
        <img
          :class="{ 'arrow-up': optionShow }"
          src="@/assets/icons/Drop.svg"
          alt=""
        />
      </div>
      <div v-if="optionShow && options.length > 0" class="select-options">
        <div
          v-for="item in options"
          :key="item.label"
          class="select-option"
          :class="{ selected: value === item.value }"
          @click="optionSelect(item.value)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  name: 'SelectInput',
  props: ['title', 'value', 'options', 'placeholder'],
  data() {
    return {
      optionShow: false
    }
  },
  computed: {
    ...mapState(['userFlatObj']),
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.optionShow = false
      }
    },
    selectLabel() {
      let str = ''
      if (Array.isArray(this.value)) {
        let option = this.options.find((item) => {
          if (item.value.length === this.value.length) {
            let isEqual = true
            item.value.forEach((p) => {
              if (!this.value.includes(p)) isEqual = false
            })
            return isEqual
          } else return false
        })
        return option?.label
      } else {
        str = this.options.find((item) => item.value === this.value)?.label
      }

      return str
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    getIcon(fn) {
      return require(`@/assets/icons/${fn}`)
    },
    handleClick() {
      this.optionShow = !this.optionShow
    },
    optionSelect(value) {
      let tmpVal = value
      this.optionShow = false
      this.$emit('input', tmpVal)
    },
    onClickOutside() {
      this.optionShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}

.wrap-select-input {
  width: 100%;
}
.title {
  @include font_style(px2rem(14), px2rem(21));
  margin-bottom: 4px;
}

.select {
  width: 100%;
  position: relative;
}

.select.active .select-selected::after {
  transform: rotate(180deg);
}

.select-selected {
  display: flex;
  align-items: center;
  width: 100%;
  height: px2rem(36);
  background: #ffffff;
  border-radius: px2rem(8);
  padding: 0 px2rem(12);
  position: relative;

  img {
    width: px2rem(16);
    height: px2rem(16);
    @include filter_191919;
    transition: all 200ms ease-in-out;

    &.arrow-up {
      transform: rotate(180deg);
    }
  }
}

input {
  outline: none;
  border: none;
  width: 100%;
  height: px2rem(36);
  @include font_style(px2rem(16), px2rem(24), #191919);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::placeholder {
    color: #1919194D;
    font-weight: 300;
  }

  &:lang(ja)::placeholder {
    font-size: 1rem;
  }
}

.select-options {
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  background: #151b35;
  padding: px2rem(6) 0;
  border: 1px solid #4a5c78;
  border-radius: 8px;
  z-index: 2;
  transition: all 250ms ease-in-out;
  min-height: px2rem(48);
  max-height: px2rem(500);
  overflow: overlay;
}

.select-option {
  user-select: none;
  padding: 4px 6px;
  @include font_style(px2rem(16), px2rem(24), #ffffff);
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.select-option:not(.selected):hover {
  background: #4a5c78;
}

.selected {
  background: #6e7d93;
}

.select-option .icon {
  width: px2rem(20);
  margin-right: px2rem(16);
  display: flex;
  justify-content: center;
}

.checked {
  background: #ffc600;
  border-color: #ffc600;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: px2rem(2);
  border-radius: px2rem(2);
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: px2rem(2);
  border-radius: px2rem(2);
  background: #9d9d9d;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: px2rem(6);
}

::-webkit-scrollbar-corner {
  background: #151b35;
}

</style>
